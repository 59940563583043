import React from "react";

import * as FaIcons from "react-icons/fa";
import BASIC_INFORMATION from "./basic";

export function LINKS () {
    return [
        {
            title: 'Website',
            path: BASIC_INFORMATION.linkWebsite,
            icon: <FaIcons.FaGlobe />,
            cName: "link-text"
        },
        {
            title: 'Resume',
            path: BASIC_INFORMATION.linkResume,
            icon: <FaIcons.FaFile />,
            cName: "link-text"
        },
        {
            title: 'Portfolio',
            path: BASIC_INFORMATION.linkPortfolio,
            icon: <FaIcons.FaRegBookmark />,
            cName: "link-text"
        },
        {
            title: 'About me',
            path: BASIC_INFORMATION.linkAboutMe,
            icon: <FaIcons.FaUserTie />,
            cName: "link-text"
        },
        {
            title: 'Cooperation',
            path: BASIC_INFORMATION.linkCooperation,
            icon: <FaIcons.FaHeart />,
            cName: "link-text"
        },
        {
            title: 'Contacts',
            path: BASIC_INFORMATION.linkContacts,
            icon: <FaIcons.FaEnvelope />,
            cName: "link-text"
        },

    ];
}

export default LINKS();