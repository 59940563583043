import React from 'react';
import {useTranslation} from "react-i18next";
import Section from "../components/section";
import BASIC_INFORMATION from "../storage/information/basic";
import LINKS from "../storage/information/links";
import SOCIAL_LINKS  from "../storage/information/social_links";
import * as FaIcons from "react-icons/fa";
import Language from "../components/language";
import useAnalyticsEventTracker from "../g-analytics/useAnalyticsEventTracker";

function HomePage(props) {

    const { t, i18n } = useTranslation()

    var time = new Date();
    var dayPart = time.toLocaleString('en-US', { hour: 'numeric', hour12: true });
    console.log(dayPart)

    const gaEventTracker = useAnalyticsEventTracker('Home');

    return (<div className={' min-vh-100 p-5'}>

        <div className="container rounded-4 shadow-lg- p-md-2 mb-md-0 mb-5 pb-md-0 pb-0 mt-5">

            <div className="d-flex justify-content-between align-items-center">
                <div className="col-md-6 d-none d-md-block">
                    { !dayPart.includes('PM')
                        ? <img src="https://images.unsplash.com/photo-1542296030-4d54bac0fc3a"  alt="image" className={'img-fluid rounded-start hidden-block'}/>
                        : <img src="https://images.unsplash.com/photo-1498335746477-0c73d7353a07"  alt="image" className={'img-fluid rounded-start hidden-block'}/>
                    }
                </div>
                <div className="col-md-6 text-start p-md-5 p-1">
                    <span className="lead text-secondary">{t('position')}</span>
                    <h1>
                        {t('name')}
                    </h1>
                    <br/>
                    {/*<span className="text-secondary">Links</span>*/}
                    <div className="border shadow rounded-4 p-2 mb-5 d-flex flex-wrap">
                        {
                            LINKS.map(link => <div className="col-md-4 col-6 d-flex p-2">
                                <div className={'col-md-3'}>
                                    {
                                        link.icon
                                    }
                                </div>
                               <div className={'col-md-9'}>
                                   <a onClick={()=>gaEventTracker('link-' + link.title)} href={link.path} className={link.cName}>
                                       {
                                           link.title
                                       }
                                   </a>
                               </div>
                            </div>)
                        }
                    </div>
                    {/*<span className="text-secondary">Follow me</span>*/}
                    <div className="links text-start pt-2 d-flex flex-wrap bg-dark rounded-4">
                        {
                            SOCIAL_LINKS.map(social_link => <div className="p-3 col-md-4 col-6">
                                <a onClick={()=>gaEventTracker('social-link-' + social_link.title)} href={social_link.path} className={'text-white'}> {social_link.icon} <span>{social_link.title}</span></a>
                            </div>)
                        }
                    </div>
                </div>
            </div>
        </div>

        <div className="container border shadow rounded-4 d-flex bg-dark p-2 mb-5 flex-md-row flex-column justify-content-between align-items-center">

            <div className="footer-contacts text-start w-md-50 w-100 pt-1 p-1">

                <span className={'text-secondary p-3'}>{t('nav.contacts')}</span>
                <div className="links text-start pt-4 p-3 mb-5 pb-5">

                    <a onClick={()=>gaEventTracker('phone')} href={'tel.:' + BASIC_INFORMATION.phoneKyivstar} className={'text-white'}> <FaIcons.FaMobileAlt />
                        <span>{BASIC_INFORMATION.phoneKyivstar}</span>
                    </a>
                    <br/> <br/>
                    <a onClick={()=>gaEventTracker('mail')} href={
                        'mailto:' + BASIC_INFORMATION.email + '?subject=Q&A-Footer'
                    } className={'text-white'}><FaIcons.FaEnvelope /> <span> {BASIC_INFORMATION.email}</span></a> <br/> <br/>
                    <a onClick={()=>gaEventTracker('website')} href={
                        BASIC_INFORMATION.linkWebsite
                    } className={'text-white'}><FaIcons.FaGlobe /> <span> {BASIC_INFORMATION.linkWebsite}</span></a>
                </div>

                <div className="d-flex justify-content-between align-items-center footer-links-information">
                    <div className="m-0 d-none d-md-block p-3">
                        <a onClick={()=>gaEventTracker('page-about-me')} href="https://lukyanchuk.com/about-me" className={'text-secondary p-1'}>{t('nav.about me')}</a>|
                        <a onClick={()=>gaEventTracker('page-portfolio')} href="https://lukyanchuk.com/portfolio" className={'text-secondary p-1'}>{t('nav.portfolio')}</a>|
                        <a onClick={()=>gaEventTracker('page-resume')} href="https://resume.lukyanchuk.com/" className={'text-secondary p-1'}>{t('nav.resume')}</a>|
                        <a onClick={()=>gaEventTracker('page-cooperation')} href="https://lukyanchuk.com/cooperation" className={'text-secondary p-1'}>{t('nav.cooperation')}</a>|
                        <a onClick={()=>gaEventTracker('page-contacts')} href="https://lukyanchuk.com/contacts" className={'text-secondary p-1'}>{t('nav.contacts')}</a>
                    </div>
                </div>
            </div>

            <div className="footer-qrcode w-md-50 w-100 border- ">

                <div className="d-none d-md-block">
                    <div className="row d-flex p-1 hidden-block">
                        <div className="qrcode-text col-md-6 text-center d-flex align-items-center">
                            <div className="content">
                                <h2 className={'text-white'}>
                                    {t('qrcode.scan')}
                                </h2>

                                <br/>

                                <img src="/assets/icon/arrow.png" alt="icon-arrow" className={'w-25'}/>
                            </div>
                        </div>

                        <div className="qrcode-image col-md-6 text-end">
                            <img src="/assets/qrcode/qr-code.png" alt="qr-code" className={'img-fluid shadow w-75 rounded-4'}/>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>);
}

export default HomePage;
