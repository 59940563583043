import React from "react";
import './../App.css';
import Language from "./language";
import {useTranslation} from "react-i18next";

function Header() {

    const { t, i18n } = useTranslation();

    return (
       <div className="container p-0">
           <header className="fixed-top p-4 d-flex justify-content-between align-items-center bg-dark-">
              <div className="col-md-11 text-start">
                  <h5> {t('nav.welcome')}</h5>
              </div>

               <div className="col-md-1 text-end">
                   <Language />
               </div>
           </header>
       </div>
    );
}

export default Header;
