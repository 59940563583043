import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Language from "./language";

import * as FaIcons from "react-icons/fa";
import BASIC_INFORMATION from '../storage/information/basic';

function Footer() {
    const { t, i18n } = useTranslation();

    return (
        <footer id={'contacts'} className={'footer text-start p-md-5 p-2'}>
            <div className="container">
                <div className="footer-header mb-5 d-flex justify-content-between">
                    <div className="col-md-9">
                        <h5 className={'title-section p-2'}> {t('footer')}</h5>
                    </div>
                    <div className="col-md-3 text-end">
                        <img src="/assets/logo/logo.png" alt="logo" className={'img-fluid w-50'}/>
                    </div>
                </div>

                <hr/>

                <div className="footer-copyright-information pt-4 text-md-start text-center p-md-0 pb-4">
                    <span className={'text-secondary'}>{t('copyright')} © {(new Date().getFullYear())}.  {t('name')}.</span>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
