import Footer from "./components/footer";
import Header from "./components/header";
import 'bootstrap/dist/css/bootstrap.min.css';
import HomePage from "./pages/home";
import {Routes, Route, Link} from "react-router-dom";
import PageNotFound from "./pages/404";
import BackToTop from "react-back-to-top-button";
import React, {useEffect, useState} from "react";
import 'animate.css';
import ReactGA from 'react-ga';
const TRACKING_ID = "UA-86660902-7"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {

    // const [services, setServices] = useState('');
    //
    // useEffect(()=>{
    //     setServices(localStorage.getItem("services"))
    //     console.log('services' + services);
    // })

    return (
        <div className="App">
            <Header/>
            <main>
                <Routes>
                    <Route index exact path={'/'} element={<HomePage/>}/>

                    <Route path="*" element={<PageNotFound/>}/>
                </Routes>
            </main>
            <BackToTop
                showOnScrollUp
                showAt={100}
                speed={1500}
                easing="easeInOutQuint"
            >
                <div className="button-top d-flex align-items-center">
                    <img src="/assets/icon/arrow-top.png" alt="icon-arrow-top" className={'arrow'}/>
                    {/*<span className={''}>Top</span>*/}
                </div>
            </BackToTop>
            <Footer/>
        </div>
    );
}

export default App;
