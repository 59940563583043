import BASIC_INFORMATION from "./basic";
import * as FaIcons from "react-icons/fa";
import React from "react";

function SOCIAL_LINKS () {

    return [
        {
            title: 'GitHub',
            path: BASIC_INFORMATION.socialGitHub,
            icon: <FaIcons.FaGithub />,
            cName: "link-text"
        },
        {
            title: 'GitLab',
            path: BASIC_INFORMATION.socialGitLab,
            icon: <FaIcons.FaGitlab />,
            cName: "link-text"
        },
        {
            title: 'LinkedIn',
            path: BASIC_INFORMATION.socialLinkedIn,
            icon: <FaIcons.FaLinkedinIn />,
            cName: "link-text"
        },
        {
            title: 'Telegram',
            path: BASIC_INFORMATION.socialTelegram,
            icon: <FaIcons.FaTelegram />,
            cName: "link-text"
        },
        {
            title: 'WhatsApp',
            path: BASIC_INFORMATION.socialWhatsApp,
            icon: <FaIcons.FaWhatsapp />,
            cName: "link-text"
        },
        {
            title: 'Viber',
            path: BASIC_INFORMATION.socialViber,
            icon: <FaIcons.FaViber />,
            cName: "link-text"
        },
        {
            title: 'Instagram',
            path: BASIC_INFORMATION.socialInstagram,
            icon: <FaIcons.FaInstagram />,
            cName: "link-text"
        },
        {
            title: 'Twitter',
            path: BASIC_INFORMATION.socialTwitter,
            icon: <FaIcons.FaTwitter />,
            cName: "link-text"
        },
        {
            title: 'Facebook',
            path: BASIC_INFORMATION.socialFacebook,
            icon: <FaIcons.FaFacebook />,
            cName: "link-text"
        },
        {
            title: 'YouTube',
            path: BASIC_INFORMATION.socialYouTube,
            icon: <FaIcons.FaYoutube />,
            cName: "link-text"
        },
        {
            title: 'BlaBlaCar',
            path: BASIC_INFORMATION.socialBlaBlaCar,
            icon: <FaIcons.FaCar />,
            cName: "link-text"
        },
        {
            title: 'SoundCloud',
            path: BASIC_INFORMATION.socialSoundCloud,
            icon: <FaIcons.FaSoundcloud />,
            cName: "link-text"
        },
    ];
}

export default SOCIAL_LINKS();