class BasicInformation {

    constructor() {
        this.init();
    }

    init(){

        this.email = 'yarikthe@gmail.com';

        this.phoneKyivstar = '+38 (098) 075 13 57';
        this.phoneLifecell = '+38 (093) 501 90 04';

        this.phone = '0980751357';

        this.socialYouTube = 'https://youtube.com/@YarikLukyanchuk';
        this.socialInstagram = 'https://instagram.com/yariklukyanchuk/';
        this.socialFacebook = 'https://facebook.com/yarik.lukyanchuk/';
        this.socialLinkedIn = 'https://linkedin.com/in/yaroslav-lukyanchuk-a028481ab/';
        this.socialTelegram= 'https://t.me/YaroslavLukyanchuk';
        this.socialWhatsApp= 'https://api.whatsapp.com/send?phone=' + this.phone;
        this.socialViber= 'viber://contact?number=%2B' + this.phone;
        this.socialTwitter= 'https://twitter.com/yariklukyanchuk';
        this.socialGitHub= 'https://github.com/yarikthe';
        this.socialGitLab= 'https://gitlab.com/yarikthe';
        this.socialBlaBlaCar = 'https://www.blablacar.com.ua/user/show/0e711c80-4bb0-11e6-a000-0022feaadf24';
        this.socialSoundCloud = 'https://soundcloud.com/yarik-lukyanchuk';

        this.address = 'м.Житомир';

        this.linkWebsite = 'https://lukyanchuk.com';
        this.linkNews = 'https://lukyanchuk.com/news';
        this.linkResume = 'https://resume.lukyanchuk.com';
        this.linkAboutMe = 'https://lukyanchuk.com/about-me';
        this.linkContacts = 'https://lukyanchuk.com/contacts';
        this.linkCooperation = 'https://lukyanchuk.com/cooperation';
        this.linkPortfolio = 'https://lukyanchuk.com/portfolio';
    }
}

export default new BasicInformation();